import React, { FunctionComponent, HTMLAttributes } from "react";

import classNames from "classnames";

import { FlexProps } from "../../types";

import styles from "./styles.module.scss";

const Flex: FunctionComponent<FlexProps & HTMLAttributes<HTMLDivElement>> = ({
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  className,
  children,
  ...rest
}) => (
  <div
    className={classNames(
      styles.flex,
      {
        [styles.flexWrap]: flexWrap,
        [styles.flexRow]: flexDirection === "row",
        [styles.flexColumn]: flexDirection === "column",
        [styles.flexItemsEnd]: alignItems === "end",
        [styles.flexItemsStart]: alignItems === "start",
        [styles.flexItemsCenter]: alignItems === "center",
        [styles.flexContentEnd]: justifyContent === "end",
        [styles.flexContentStart]: justifyContent === "start",
        [styles.flexContentCenter]: justifyContent === "center",
        [styles.flexContentSpaceAround]: justifyContent === "around",
        [styles.flexContentSpaceEvenly]: justifyContent === "evenly",
        [styles.flexContentSpaceBetween]: justifyContent === "between",
      },
      className
    )}
    {...rest}
  >
    {children}
  </div>
);

Flex.defaultProps = {
  flexWrap: false,
};

export default Flex;
