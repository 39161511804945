import React, { FunctionComponent, HTMLAttributes } from "react";

interface SpacerProps extends HTMLAttributes<HTMLDivElement> {
  size: number;
  direction?: "vertical" | "horizontal";
}

const Spacer: FunctionComponent<SpacerProps> = ({
  size,
  direction,
  style,
  ...rest
}) => (
  <div
    style={{
      ...style,
      height: direction === "vertical" ? `${size}rem` : "100%",
      width: direction === "horizontal" ? `${size}rem` : "100%",
    }}
    {...rest}
  />
);

Spacer.defaultProps = {
  direction: "vertical",
};

export default Spacer;
