import React, { FunctionComponent } from "react";

import Text from "../Text";
import Link from "../Link";
import Spacer from "../Spacer";
import Container from "../Container";

import styles from "./styles.module.scss";
import { useIntl } from "gatsby-plugin-intl";

const Footer: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  return (
    <footer className={styles.footer}>
      <Container
        flex
        flexWrap
        alignItems="end"
        flexDirection="row"
        justifyContent="between"
      >
        <div>
          <Text size="sm">
            &copy; <b>{new Date().getFullYear()} Mosano</b>
          </Text>
          <Spacer size={0.4} />
          <Link newTab underline size="sm" to="https://mosano.eu/">
            mosano.eu
          </Link>
        </div>
        <div className={styles.linksRow}>
          <Link gatsby size="sm" to="/condicoes">
            {formatMessage({ id: "footer_terms_label" })}
          </Link>
          <div className={styles.divider} />
          <Link gatsby size="sm" to="/privacidade">
           {formatMessage({ id: "footer_privacy_label" })}
          </Link>
        </div>
      </Container>
    </footer>
  )
};

export default Footer;
