import React, { FunctionComponent } from "react";

import classNames from "classnames";

import { FlexProps } from "../../types";

import containerStyles from "./styles.module.scss";
import flexStyles from "../Flex/styles.module.scss";

interface ContainerProps extends FlexProps {
  flex?: boolean;
}

const Container: FunctionComponent<ContainerProps> = ({
  flex,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  children,
}) => (
  <div
    className={classNames(containerStyles.container, {
      [flexStyles.flex]: flex,
      [flexStyles.flexWrap]: flexWrap,
      [flexStyles.flexRow]: flexDirection === "row",
      [flexStyles.flexColumn]: flexDirection === "column",
      [flexStyles.flexItemsEnd]: alignItems === "end",
      [flexStyles.flexItemsStart]: alignItems === "start",
      [flexStyles.flexItemsCenter]: alignItems === "center",
      [flexStyles.flexContentEnd]: justifyContent === "end",
      [flexStyles.flexContentStart]: justifyContent === "start",
      [flexStyles.flexContentCenter]: justifyContent === "center",
      [flexStyles.flexContentSpaceAround]: justifyContent === "around",
      [flexStyles.flexContentSpaceEvenly]: justifyContent === "evenly",
      [flexStyles.flexContentSpaceBetween]: justifyContent === "between",
    })}
  >
    {children}
  </div>
);

Container.defaultProps = {
  flex: false,
  flexWrap: false,
};

export default Container;
