import React, { FunctionComponent } from "react";
import { injectIntl } from "gatsby-plugin-intl"

import Footer from "../Footer";
import Header from "../Header";

interface LayoutProps {
  header?: boolean;
  footer?: boolean;
  transparentHeader?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = ({
  header,
  footer,
  transparentHeader,
  children,
}) => (
  <>
    {header && <Header transparent={transparentHeader} />}
    <main>{children}</main>
    {footer && <Footer />}
  </>
);

Layout.defaultProps = {
  header: true,
  footer: true,
  transparentHeader: false,
};

export default injectIntl(Layout);
