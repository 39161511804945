import React, { FunctionComponent, HTMLAttributes } from "react";

import classNames from "classnames";

import { TypographyProps } from "../../types";

interface TextProps
  extends HTMLAttributes<
    HTMLHeadingElement & HTMLParagraphElement & HTMLSpanElement
  > {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  h6?: boolean;
  p?: boolean;
}

const Text: FunctionComponent<TextProps & TypographyProps> = ({
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  size,
  weight,
  italic,
  underline,
  uppercase,
  className,
  children,
  ...rest
}) => {
  const textClassName = classNames(
    `text-size-${size}`,
    `text-weight-${weight}`,
    {
      "text-style-italic": italic,
      "text-transform-uppercase": uppercase,
      "text-decoration-underline": underline,
    },
    className
  );

  return (
    (h1 && (
      <h1 className={textClassName} {...rest}>
        {children}
      </h1>
    )) ||
    (h2 && (
      <h2 className={textClassName} {...rest}>
        {children}
      </h2>
    )) ||
    (h3 && (
      <h3 className={textClassName} {...rest}>
        {children}
      </h3>
    )) ||
    (h4 && (
      <h4 className={textClassName} {...rest}>
        {children}
      </h4>
    )) ||
    (h5 && (
      <h5 className={textClassName} {...rest}>
        {children}
      </h5>
    )) ||
    (h6 && (
      <h6 className={textClassName} {...rest}>
        {children}
      </h6>
    )) ||
    (p && (
      <p className={textClassName} {...rest}>
        {children}
      </p>
    )) || (
      <span className={textClassName} {...rest}>
        {children}
      </span>
    )
  );
};

Text.defaultProps = {
  h1: false,
  h2: false,
  h3: false,
  h4: false,
  h5: false,
  h6: false,
  p: false,
  italic: false,
  uppercase: false,
  underline: false,
  weight: "rg",
  size: "rg",
};

export default Text;
