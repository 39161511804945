import React, { FunctionComponent } from "react";

import { Link } from "gatsby-plugin-intl";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface NavButtonProps {
  to: string;
  blue?: boolean;
  padded?: boolean;
  selected?: boolean;
  hasMarginBottom?: boolean;
}

const NavButton: FunctionComponent<NavButtonProps> = ({
  to,
  blue,
  padded,
  selected,
  hasMarginBottom,
  children,
}) => (
  <Link
    to={to}
    className={classNames(styles.navButton, {
      [styles.navButtonBlue]: blue,
      [styles.navButtonPadded]: padded,
      [styles.navButtonSelected]: selected,
      [styles.navButtonLightBg]: padded && !blue,
      [styles.navButtonMarginBottom]: hasMarginBottom,
    })}
  >
    {children}
  </Link>
);

NavButton.defaultProps = {
  blue: false,
  padded: false,
  selected: false,
  hasMarginBottom: false,
};

export default NavButton;
