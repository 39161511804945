import React, { FunctionComponent } from "react";

import classNames from "classnames";

import AlertIcon from "../../../assets/imgs/icons/alert.svg";
import ArrowRightIcon from "../../../assets/imgs/icons/arrow-right.svg";
import BookmarkIcon from "../../../assets/imgs/icons/bookmark.svg";
import BookmarkFilledIcon from "../../../assets/imgs/icons/bookmark-filled.svg";
import BucketIcon from "../../../assets/imgs/icons/bucket.svg";
import BucketFilledIcon from "../../../assets/imgs/icons/bucket-filled.svg";
import CheckmarkIcon from "../../../assets/imgs/icons/checkmark.svg";
import CheckmarkmSmallIcon from "../../../assets/imgs/icons/checkmark-small.svg";
import CookieIcon from "../../../assets/imgs/icons/cookie.svg";
import CrossIcon from "../../../assets/imgs/icons/cross.svg";
import CrossSmallIcon from "../../../assets/imgs/icons/cross-small.svg";
import EditIcon from "../../../assets/imgs/icons/edit.svg";
import EditFilledIcon from "../../../assets/imgs/icons/edit-filled.svg";
import EmailIcon from "../../../assets/imgs/icons/email.svg";
import FacebookIcon from "../../../assets/imgs/icons/facebook.svg";
import GoogleIcon from "../../../assets/imgs/icons/google.svg";
import HeartIcon from "../../../assets/imgs/icons/heart.svg";
import HeartFilledIcon from "../../../assets/imgs/icons/heart-filled.svg";
import InfoIcon from "../../../assets/imgs/icons/info.svg";
import InfoFilledIcon from "../../../assets/imgs/icons/info-filled.svg";
import InstagramIcon from "../../../assets/imgs/icons/instagram.svg";
import LoadingIcon from "../../../assets/imgs/icons/loading.svg";
import MenuIcon from "../../../assets/imgs/icons/menu.svg";
import MessengerIcon from "../../../assets/imgs/icons/messenger.svg";
import PlusIcon from "../../../assets/imgs/icons/plus.svg";
import PlusSmallIcon from "../../../assets/imgs/icons/plus-small.svg";
import SearchIcon from "../../../assets/imgs/icons/search.svg";
import StarIcon from "../../../assets/imgs/icons/star.svg";
import StarFilledIcon from "../../../assets/imgs/icons/star-filled.svg";
import TwitterIcon from "../../../assets/imgs/icons/twitter.svg";
import WhatsappIcon from "../../../assets/imgs/icons/whatsapp.svg";
import YoutubeIcon from "../../../assets/imgs/icons/youtube.svg";

import styles from "./styles.module.scss";

export type Icons =
  | "alert"
  | "arrowRight"
  | "bookmark"
  | "bookmarkFilled"
  | "bucket"
  | "bucketFilled"
  | "checkmark"
  | "checkmarkSmall"
  | "cookie"
  | "cross"
  | "crossSmall"
  | "edit"
  | "editFilled"
  | "email"
  | "facebook"
  | "google"
  | "heart"
  | "heartFilled"
  | "info"
  | "infoFilled"
  | "instagram"
  | "loading"
  | "menu"
  | "messenger"
  | "plus"
  | "plusSmall"
  | "search"
  | "star"
  | "starFilled"
  | "twitter"
  | "whatsapp"
  | "youtube";

interface IconProps {
  name: Icons;
  size?: number;
  spin?: boolean;
  className?: string;
  onClick?: () => void;
}

const Icon: FunctionComponent<IconProps> = ({
  name,
  size,
  spin,
  className,
  onClick,
}) => {
  const SVGIcon =
    (name === "alert" && AlertIcon) ||
    (name === "arrowRight" && ArrowRightIcon) ||
    (name === "bookmark" && BookmarkIcon) ||
    (name === "bookmarkFilled" && BookmarkFilledIcon) ||
    (name === "bucket" && BucketIcon) ||
    (name === "bucketFilled" && BucketFilledIcon) ||
    (name === "checkmark" && CheckmarkIcon) ||
    (name === "checkmarkSmall" && CheckmarkmSmallIcon) ||
    (name === "cookie" && CookieIcon) ||
    (name === "cross" && CrossIcon) ||
    (name === "crossSmall" && CrossSmallIcon) ||
    (name === "edit" && EditIcon) ||
    (name === "editFilled" && EditFilledIcon) ||
    (name === "email" && EmailIcon) ||
    (name === "facebook" && FacebookIcon) ||
    (name === "google" && GoogleIcon) ||
    (name === "heart" && HeartIcon) ||
    (name === "heartFilled" && HeartFilledIcon) ||
    (name === "info" && InfoIcon) ||
    (name === "infoFilled" && InfoFilledIcon) ||
    (name === "instagram" && InstagramIcon) ||
    (name === "loading" && LoadingIcon) ||
    (name === "menu" && MenuIcon) ||
    (name === "messenger" && MessengerIcon) ||
    (name === "plus" && PlusIcon) ||
    (name === "plusSmall" && PlusSmallIcon) ||
    (name === "search" && SearchIcon) ||
    (name === "star" && StarIcon) ||
    (name === "starFilled" && StarFilledIcon) ||
    (name === "twitter" && TwitterIcon) ||
    (name === "whatsapp" && WhatsappIcon) ||
    (name === "youtube" && YoutubeIcon);

  return (
    <SVGIcon
      width={size}
      height={size}
      onClick={onClick}
      className={classNames(className, {
        [styles.spin]: spin,
        [styles.clickable]: onClick !== undefined,
      })}
    />
  );
};

Icon.defaultProps = {
  spin: false,
};

export default Icon;
