import { useLocation, navigate } from "@reach/router";
import React, { useState, FunctionComponent } from "react";

import classNames from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import { Link, useIntl } from "gatsby-plugin-intl";
import Select from "react-dropdown-select";

import Text from "../Text";
import Icon from "../Icon";
import Flex from "../Flex";
import Spacer from "../Spacer";
import Container from "../Container";
import NavButton from "./components/NavButton";

import styles from "./styles.module.scss";

interface HeaderProps {
  transparent?: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({ transparent }) => {
  const { pathname } = useLocation();
  const [showMobileHeader, setShowMobileHeader] = useState<boolean>(false);

  const data = useStaticQuery<{
    colorLogo: { publicURL: string };
  }>(graphql`
    query {
      colorLogo: file(relativePath: { eq: "logos/take-eat.png" }) {
        publicURL
      }
    }
  `);

  const toggleMobileHeader = (): void => {
    setShowMobileHeader(!showMobileHeader);
    if (!showMobileHeader) {
      document?.body?.classList?.add("overflow-hidden");
    } else {
      document?.body?.classList?.remove("overflow-hidden");
    }
  };
  const intl = useIntl();
  const headerNavigation = (mobile?: boolean): JSX.Element => (
    <>
      {/* <NavButton
        to={`/parcerias`}
        hasMarginBottom={mobile}
        padded={!mobile && transparent}
        blue={!mobile && pathname === "/"}
        selected={pathname === `/parcerias`}
      >
        {intl.formatMessage({ id: "header_partner_label" })}
      </NavButton> */}
      <NavButton
        to={`/registo`}
        hasMarginBottom={mobile}
        padded={!mobile && transparent}
        blue={!mobile && pathname === "/"}
        selected={pathname === `/registo`}
      >
        {intl.formatMessage({ id: "header_be_partner_label" })}
      </NavButton>
      <NavButton
        to={`/contactos`}
        hasMarginBottom={mobile}
        padded={!mobile && transparent}
        blue={!mobile && pathname === "/"}
        selected={pathname === `/contactos`}
      >
        {intl.formatMessage({ id: "header_contacts_label" })}
      </NavButton>
      <div className={styles.navSelector}>
        <Select
          values={[
            {
              label: intl.locale === "en" ? "English" : "Português",
              value: intl.locale === "en" ? "en" : "pt",
            },
          ]}
          options={[
            { label: "Português", value: "pt" },
            { label: "English", value: "en" },
          ]}
          searchable={false}
          onChange={(lang) => navigate(`/${lang[0].value}`)}
          keepSelectedInList={true}
          className={styles.innerSelect}
        />
      </div>
    </>
  );

  const desktopHeader = (): JSX.Element => (
    <Container
      flex
      flexDirection="row"
      alignItems="center"
      justifyContent="between"
    >
      <Link to={`/`}>
        <img
          width="142"
          height="32"
          alt="Take-Eat Logo"
          src={data.colorLogo.publicURL}
        />
      </Link>
      <Icon
        size={30}
        name="menu"
        onClick={toggleMobileHeader}
        className={styles.headerMobileMenu}
      />
      <div className={styles.headerDesktopNavigation}>{headerNavigation()}</div>
    </Container>
  );

  const mobileHeader = (): JSX.Element => (
    <div
      className={classNames(styles.mobileHeader, {
        [styles.mobileHeaderOpen]: showMobileHeader,
      })}
    >
      <Flex flexDirection="row" justifyContent="end">
        <Icon size={30} name="cross" onClick={toggleMobileHeader} />
      </Flex>
      <Spacer size={3} />
      <Flex flexDirection="column">{headerNavigation(true)}</Flex>
      <Spacer size={6.85} />
      <Text h5 weight="sb" uppercase className={styles.mosano}>
        Mosano
      </Text>
      <Spacer size={0.8} />
      <Text size="sm" weight="bl">
        {intl.formatMessage({ id: "contactPage_label_contacts" })}
      </Text>
      <Spacer size={0.4} />
      <Text size="sm">hello@mosano.eu</Text>
      <Spacer size={0.2} />
      <Text size="sm">+351 914 143 912</Text>
      <Spacer size={1.2} />
      <Text size="sm" weight="bl">
        {intl.formatMessage({ id: "contactPage_label_address" })}
      </Text>
      <Spacer size={0.4} />
      <address className={styles.address}>
        Rua D. João I, nº 256
        <br />
        4450-162 Matosinhos
        <br />
        Portugal
      </address>
    </div>
  );

  return (
    <header
      className={classNames(styles.header, {
        [styles.headerTransparent]: transparent,
        [styles.headerExtraPadded]: !transparent,
      })}
    >
      {desktopHeader()}
      {mobileHeader()}
    </header>
  );
};

Header.defaultProps = {
  transparent: false,
};

export default Header;
