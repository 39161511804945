import React, { FunctionComponent } from "react";

import classNames from "classnames";
import { Link as GatsbyLink } from "gatsby-plugin-intl";

import { TypographyProps } from "../../types";

interface LinkProps extends TypographyProps {
  to: string;
  gatsby?: boolean;
  newTab?: boolean;
  className?: string;
}

const Link: FunctionComponent<LinkProps> = ({
  to,
  size,
  weight,
  italic,
  uppercase,
  underline,
  gatsby,
  newTab,
  className,
  children,
}) => {
  const linkClassName = classNames(
    `text-size-${size}`,
    `text-weight-${weight}`,
    {
      "text-style-italic": italic,
      "text-transform-uppercase": uppercase,
      "text-decoration-underline": underline,
    },
    className
  );

  return gatsby ? (
    <GatsbyLink to={to} className={linkClassName}>
      {children}
    </GatsbyLink>
  ) : (
    <a
      href={to}
      className={linkClassName}
      target={newTab && "_blank"}
      rel={newTab && "noopener noreferrer"}
    >
      {children}
    </a>
  );
};

Link.defaultProps = {
  gatsby: false,
  newTab: false,
  italic: false,
  uppercase: false,
  underline: false,
  weight: "rg",
  size: "rg",
};

export default Link;
